import ExternalContentCard, { ContentCardSkeletons } from "../common/ExternalContentCard";
import React, { useEffect, useState } from "react";

import Analytics from "./Analytics";
import BillingPanel from "../employers/BillingPanel";
import Box from "@mui/material/Box";
import ComingSoonPanel from "../common/ComingSoonPanel";
import Grid from "@mui/material/Grid";
import JobAdminPanel from "./../jobs/JobAdminPanel";
import PageContainer from "../common/PageContainer";
import Paper from "@mui/material/Paper";
import ProfileSearchPage from "../candidates/ProfileSearchPage";
import SideCard from "../common/SideCard";
import Stack from "@mui/material/Stack";
import Tab from "@mui/material/Tab";
import TabPanel from "../common/TabPanel";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import UserAdminPanel from "../employers/UserAdminPanel";
import axiosInstance from "../axiosInstance";
import { useTheme } from "@mui/material/styles";

const AdminTabPanels = ({ employer }) => {
    const [value, setValue] = React.useState(0);
    const theme = useTheme();

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const tabStyle = {
        backgroundColor: "white",
        color: theme.palette.text.primary,
        borderRadius: "6px",
        margin: "0px 12px",
        flexGrow: 1,
        "&.Mui-selected": {
            backgroundColor: theme.palette.highlight.main,
            color: theme.palette.text.primary,
        },
        "&:first-of-type": {
            marginLeft: "0px",
        },
        "&:last-of-type": {
            marginRight: "0px",
        },
    };
    return (
        <>
            <Tabs
                value={value}
                onChange={handleChange}
                sx={{
                    padding: "10px 0px",
                    "& .MuiTabs-indicator": {
                        display: "none",
                    },
                    "& .MuiTabScrollButton-root ": {
                        color: "white",
                    },
                }}
                variant="scrollable"
                scrollButtons="auto"
                allowScrollButtonsMobile
            >
                <Tab label="Jobs" sx={tabStyle} />
                <Tab label="Candidate Profiles" sx={tabStyle} />
                <Tab label="Analytics" sx={tabStyle} />
                <Tab label="Users" sx={tabStyle} />
                <Tab label="Billing" sx={tabStyle} />
                <Tab label="Resources" sx={tabStyle} />
            </Tabs>
            <Paper sx={{ padding: "20px" }}>
                <JobAdminPanel value={value} index={0} employer={employer} />
                <TabPanel value={value} index={1}>
                    <ProfileSearchPage employer={employer} />
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <Analytics employer={employer} />
                </TabPanel>
                <UserAdminPanel value={value} index={3} employer={employer} />
                <BillingPanel value={value} index={4} employer={employer} />
                <TabPanel value={value} index={5}>
                    <ResourcesPanel value={value} index={5} employer={employer} />
                </TabPanel>
            </Paper>
        </>
    );
};

const EmployerAdminPage = () => {
    const [employer, setEmployer] = useState({});
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        axiosInstance.get(`/api/auth/users/me/`).then((response) => {
            response.data.employer.premium = response.data.premium;
            setEmployer(response.data.employer);
            setLoading(false);
        });
    }, []);

    return employer ? (
        <PageContainer maxWidth="xl" sx={{ paddingTop: 0, backgroundColor:"#283d72"  }}>
            {loading ? null : <AdminTabPanels employer={employer} />}
        </PageContainer>
    ) : null;
};

const ResourcesPanel = ({ employer }) => {
    const [resources, setResources] = useState([]);
    useEffect(() => {
        axiosInstance.get("/api/external_content/?limit=100").then((response) => {
            setResources(response.data.results);
        });
    }, []);

    return !!resources.length ? (
        <Grid container spacing={4}>
            {resources.map((resource) => (
                <Grid item md={4} sm={6} xs={12}>
                    <ExternalContentCard key={resource.id} content={resource} />
                </Grid>
            ))}
        </Grid>
    ) : (
        <ContentCardSkeletons count={6} />
    );
};
export default EmployerAdminPage;
