import React, { useEffect, useState } from "react";
import { format, parse } from "date-fns";
import { useLocation, useNavigate } from "react-router-dom";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import Button from "@mui/material/Button";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import DuplicateJobDisplay from "./DuplicateJobDisplay";
import DoneIcon from "@mui/icons-material/Done";
import Fab from "@mui/material/Fab";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import FormHelperText from "@mui/material/FormHelperText";
import Grid from "@mui/material/Grid";
import HelpIcon from "@mui/icons-material/Help";
import InputLabel from "@mui/material/InputLabel";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import MarkdownEditor from "../common/MarkdownEditor";
import MenuItem from "@mui/material/MenuItem";
import PageContainer from "../common/PageContainer";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import axiosInstance from "../axiosInstance";
import { getAccountType } from "../auth/functions";
import { useTheme } from "@mui/material/styles";

const NewJobPage = ({ user }) => {
    const theme = useTheme();
    let location = useLocation();
    let navigate = useNavigate();
    const [options, setOptions] = useState({});
    const [categories, setCategories] = useState([]);
    const [jobTypes, setJobTypes] = useState([]);
    const [errors, setErrors] = useState({});
    const [employers, setEmployers] = useState([]);
    const [currencies, setCurrencies] = useState([]);
    const [job, setJob] = useState({
        title: "",
        job_type: "",
        category: "",
        duration: {
            permanent: false,
        },
        part_time: false,
        city: "",
        country: "GB",
        application_deadline: null,
        rolling_deadline: false,
        link_to_application_form: "",
        description: "",
        employer_id: "",
        salary_range_upper_currency: "GBP",
        salary_range_lower_currency: "GBP",
        salary_range_lower: "",
        salary_range_upper: "",
        early_careers: true,
        ...location?.state?.job,
        expired: false,
        private: true,
    });
    const [jobs, setJobs] = useState([]);
    useEffect(() => {
        if (user?.employer?.id) {
            setJob((prevJob) => ({ ...prevJob, employer_id: user.employer.id }));
        }
    }, [user]);

    useEffect(() => {
        axiosInstance.options("/api/jobs/").then((response) => {
            setOptions(response.data.actions.POST);
        });
    }, []);

    useEffect(() => {
        axiosInstance.get("/api/jobs/categories/").then((response) => {
            setCategories(response.data);
        });
    }, []);

    useEffect(() => {
        axiosInstance.get("/api/jobs/currencies/").then((response) => {
            setCurrencies(response.data);
        });
    }, []);

    useEffect(() => {
        axiosInstance.get("/api/jobs/types/").then((response) => {
            setJobTypes(response.data);
        });
    }, []);

    useEffect(() => {
        //TODO iterate pages or disable pagination
        axiosInstance.get("/api/employers/?limit=1000&ordering=name").then((response) => {
            setEmployers(response.data.results);
        });
    }, []);

    const submit = async () => {
        const job_copy = { ...job };
        if (job_copy.salary_range_lower === "") {
            delete job_copy.salary_range_lower;
        }
        if (job_copy.salary_range_upper === "") {
            delete job_copy.salary_range_upper;
        }
        for (const key in job_copy) {
            if (job_copy[key] === null) {
                delete job_copy[key];
            }
        }

        axiosInstance
            .post("/api/jobs/", job_copy)
            .then((response) => {
                navigate(`/jobs/${response.data.id}/`);
            })
            .catch((error) => {
                setErrors(error.response.data);
            });
    };

    const check = () => {
        const job_copy = { ...job };
        axiosInstance
            .post("/api/jobs/check/", job_copy)
            .then((response) => {
                setJobs(response.data)
            })
            .catch((error) => {
                    console.log("Error", error);
            });
    };

    const setJobValue = (key, value) => {
        setJob((oldJob) => ({ ...oldJob, [key]: value }));
        setErrors((oldErrors) => ({ ...oldErrors, [key]: undefined }));
    };
    const setJobDurationValue = (key, value) => {
        setJob((oldJob) => ({ ...oldJob, duration: { ...oldJob.duration, [key]: value } }));
        setErrors((oldErrors) => ({ ...oldErrors, duration: undefined }));
    };

    return (
        <PageContainer sx={{gap: '5px', backgroundColor:"#283d72" }}>
            {Object.keys(options).length ? (
            <>
                <Paper sx={{marginBottom: '3px'}}>
                    <Typography variant="h3" paragraph color="text" textAlign="center" mb={5}>
                        Post new job
                    </Typography>
                    {Object.keys(options).length ? (
                        <Grid container spacing={1}>
                            <Tooltip title="Submit job">
                                <Fab
                                    color="primary"
                                    sx={{
                                        position: "fixed",
                                        bottom: "20px",
                                        right: "40px",
                                        zIndex: 10,
                                    }}
                                    onClick={submit}
                                >
                                    <DoneIcon />
                                </Fab>
                            </Tooltip>

                            <Grid item xs={6}>
                                <TextField
                                    size="small"
                                    label="Employer"
                                    variant="filled"
                                    color="primary"
                                    required
                                    select
                                    disabled={getAccountType() === "employer"}
                                    value={job.employer_id}
                                    onChange={(e) => setJobValue("employer_id", e.target.value)}
                                    error={errors.employer_id}
                                    helperText={errors.employer_id}
                                    fullWidth
                                >
                                    {employers.map((employer) => (
                                        <MenuItem key={employer.id} value={employer.id}>
                                            {employer.name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={8}>
                                <TextField
                                    size="small"
                                    label="Title"
                                    variant="filled"
                                    color="primary"
                                    required
                                    value={job.title}
                                    onChange={(e) => setJobValue("title", e.target.value)}
                                    error={errors.title}
                                    helperText={errors.title}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <FormGroup sx={{ alignContent: "center" }}>
                                    <FormControlLabel
                                        control={<Switch color="primary" />}
                                        label="Early careers"
                                        checked={job.early_careers}
                                        onChange={(e) => setJobValue("early_careers", e.target.checked)}
                                        // error={errors.early_careers}
                                        // helperText={errors.early_careers}
                                    />
                                </FormGroup>
                            </Grid>

                            <Grid item xs={11}>
                                <TextField
                                    size="small"
                                    label="Application form link or mailto link"
                                    variant="filled"
                                    color="primary"
                                    type="url"
                                    value={job.link_to_application_form}
                                    onChange={(e) =>
                                        setJobValue("link_to_application_form", e.target.value)
                                    }
                                    error={errors.link_to_application_form}
                                    helperText={errors.link_to_application_form}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={1} md={1} sx={{ alignSelf: "center" }}>
                                <Tooltip
                                title={
                                    <span>
                                    If you are adding an email address instead of a URL
                                    write mailto: before the email address.
                                    <br />
                                    Example: 'mailto:team@spacecareers.uk'
                                    </span>
                                }
                                >
                                <HelpIcon />
                                </Tooltip>
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <TextField
                                    size="small"
                                    label="Type"
                                    variant="filled"
                                    color="primary"
                                    required
                                    select
                                    value={job.job_type}
                                    onChange={(e) => setJobValue("job_type", e.target.value)}
                                    error={errors.job_type}
                                    helperText={errors.job_type}
                                    fullWidth
                                >
                                    {jobTypes.map(({job_type_name}) => (
                                        <MenuItem key={job_type_name} value={job_type_name}>
                                            {job_type_name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <TextField
                                    size="small"
                                    label="Category"
                                    variant="filled"
                                    color="primary"
                                    select
                                    value={job.category}
                                    onChange={(e) => setJobValue("category", e.target.value)}
                                    error={errors.category}
                                    helperText={errors.category}
                                    fullWidth
                                >
                                    <MenuItem value={undefined}>
                                        <em>None</em>
                                    </MenuItem>
                                    {categories.map(({category_id, category_name, category_group_name}) =>
                                        <MenuItem
                                            key={category_id}
                                            value={category_id}
                                        >
                                            {category_group_name} - {category_name}
                                        </MenuItem>
                                    )}
                                </TextField>
                            </Grid>
                            <Grid item sm={8} md={6} lg={4}>
                                <FormControl error={errors.duration} required /*row*/ >
                                    <FormGroup row>
                                        <Stack spacing={2}>
                                            <InputLabel
                                                disableAnimation
                                                shrink
                                                sx={{ transform: "unset", fontSize: ".75em" }}
                                            >
                                                Duration
                                            </InputLabel>
                                            <Stack spacing={1} direction="row">
                                                <TextField
                                                    sx={{ width: "70px" }}
                                                    size="small"
                                                    label="Years"
                                                    variant="filled"
                                                    color="primary"
                                                    type="number"
                                                    InputProps={{
                                                        inputProps: {
                                                            max: 100,
                                                            min: 0,
                                                        },
                                                    }}
                                                    value={job.duration?.years || 0}
                                                    onChange={(e) =>
                                                        setJobDurationValue("years", e.target.value)
                                                    }
                                                    disabled={job.duration?.permanent}
                                                />
                                                <TextField
                                                    sx={{ width: "70px" }}
                                                    size="small"
                                                    label="Months"
                                                    variant="filled"
                                                    color="primary"
                                                    type="number"
                                                    InputProps={{
                                                        inputProps: {
                                                            max: 11,
                                                            min: 0,
                                                        },
                                                    }}
                                                    value={job.duration?.months || 0}
                                                    onChange={(e) =>
                                                        setJobDurationValue("months", e.target.value)
                                                    }
                                                    disabled={job.duration?.permanent}
                                                />
                                                <TextField
                                                    sx={{ width: "70px" }}
                                                    size="small"
                                                    label="Weeks"
                                                    variant="filled"
                                                    color="primary"
                                                    type="number"
                                                    InputProps={{
                                                        inputProps: {
                                                            max: 3,
                                                            min: 0,
                                                        },
                                                    }}
                                                    value={job.duration?.weeks || 0}
                                                    onChange={(e) =>
                                                        setJobDurationValue("weeks", e.target.value)
                                                    }
                                                    disabled={job.duration?.permanent}
                                                />
                                                <FormControlLabel
                                                    control={<Switch color="primary" />}
                                                    label="Permanent"
                                                    checked={job.duration?.permanent}
                                                    onChange={(e) =>
                                                        setJobDurationValue(
                                                            "permanent",
                                                            e.target.checked
                                                        )
                                                    }
                                                />
                                            </Stack>
                                        </Stack>
                                        <FormHelperText>{errors.duration}</FormHelperText>
                                    </FormGroup>
                                </FormControl>
                            </Grid>
                            <Grid item sm={4} md={2}>
                                <FormGroup sx={{ marginTop: "1em" }}>
                                    <FormControlLabel
                                        control={<Switch color="primary" />}
                                        label="Part time"
                                        checked={job.part_time}
                                        onChange={(e) => setJobValue("part_time", e.target.checked)}
                                        // error={errors.part_time}
                                        // helperText={errors.part_time}
                                        // fullWidth
                                        sx={{ height: "48px" }}
                                    />
                                </FormGroup>
                            </Grid>
                            <Grid item xs={12} md={4} lg={6}>
                                <TextField
                                    size="small"
                                    label="City"
                                    variant="filled"
                                    color="primary"
                                    required
                                    value={job.city}
                                    onChange={(e) => setJobValue("city", e.target.value)}
                                    error={errors.city}
                                    helperText={errors.city}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    size="small"
                                    label="Country"
                                    variant="filled"
                                    color="primary"
                                    required
                                    select
                                    value={job.country}
                                    onChange={(e) => setJobValue("country", e.target.value)}
                                    error={errors.country}
                                    helperText={errors.country}
                                    fullWidth
                                >
                                    {options.country.choices.map((country) => (
                                        <MenuItem key={country.value} value={country.value}>
                                            {country.display_name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    size="small"
                                    label="On Site/Remote"
                                    variant="filled"
                                    color="primary"
                                    select
                                    value={job.on_site_remote}
                                    onChange={(e) => setJobValue("on_site_remote", e.target.value)}
                                    error={errors.on_site_remote}
                                    helperText={errors.on_site_remote}
                                    fullWidth
                                >
                                    <MenuItem value={undefined}>
                                        <em>None</em>
                                    </MenuItem>
                                    {options.on_site_remote.choices
                                        .sort((a, b) => a.display_name.localeCompare(b.display_name))
                                        .map((on_site_remote) => (
                                            <MenuItem
                                                key={on_site_remote.value}
                                                value={on_site_remote.value}
                                            >
                                                {on_site_remote.display_name}
                                            </MenuItem>
                                        ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={8} md={4}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        mask="__-__-____"
                                        disabled={job.rolling_deadline}
                                        label="Application deadline"
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                error={errors.application_deadline}
                                                helperText={errors.application_deadline}
                                                required
                                            />
                                        )}
                                        value={parse(
                                            job.application_deadline,
                                            "dd-MM-yyyy",
                                            new Date()
                                        )}
                                        onChange={(newValue) => {
                                            try {
                                                setJobValue(
                                                    "application_deadline",
                                                    format(newValue, "dd-MM-yyyy")
                                                );
                                            } catch (e) {
                                                setJobValue("application_deadline", null);
                                            }
                                        }}
                                        inputFormat="dd-MM-yyyy"
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs={4} md={2}>
                                <FormGroup>
                                    <FormControlLabel
                                        control={<Switch color="primary" />}
                                        label="Rolling deadline"
                                        checked={job.rolling_deadline}
                                        onChange={(e) =>
                                            setJobValue("rolling_deadline", e.target.checked)
                                        }
                                        // error={errors.rolling_deadline}
                                        // helperText={errors.rolling_deadline}
                                    />
                                </FormGroup>
                            </Grid>
                            <Grid item xs={3} md={1}>
                                <TextField
                                    size="small"
                                    label="Currency"
                                    variant="filled"
                                    color="primary"
                                    select
                                    value={job.salary_range_upper_currency}
                                    onChange={(e) => {
                                        setJobValue("salary_range_upper_currency", e.target.value);
                                        setJobValue("salary_range_lower_currency", e.target.value);
                                    }}
                                    error={errors.salary_range_upper_currency}
                                    helperText={errors.salary_range_upper_currency}
                                    fullWidth
                                >
                                    <MenuItem value={undefined}>
                                        <em>None</em>
                                    </MenuItem>
                                    {currencies.sort().map((currency) => (
                                        <MenuItem key={currency} value={currency}>
                                            {currency}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={4} md={2}>
                                <TextField
                                    size="small"
                                    label="Lower salary range"
                                    variant="filled"
                                    color="primary"
                                    type="number"
                                    value={job.salary_range_lower}
                                    onChange={(e) => setJobValue("salary_range_lower", e.target.value)}
                                    error={errors.salary_range_lower}
                                    helperText={errors.salary_range_lower}
                                    fullWidth
                                    required={false}
                                />
                            </Grid>
                            <Grid item xs={4} md={2}>
                                <TextField
                                    size="small"
                                    label="Upper salary range"
                                    variant="filled"
                                    color="primary"
                                    type="number"
                                    value={job.salary_range_upper}
                                    onChange={(e) => setJobValue("salary_range_upper", e.target.value)}
                                    error={errors.salary_range_upper}
                                    helperText={errors.salary_range_upper}
                                    fullWidth
                                    required={false}
                                />
                            </Grid>
                            <Grid item xs={1} md={1} sx={{ alignSelf: "center" }}>
                                <Tooltip title="If entering a single salary rather than a range, enter the same value for both the upper and lower range">
                                    <HelpIcon />
                                </Tooltip>
                            </Grid>

                            <Grid item xs={12}>
                                <FormControl fullWidth error={errors.description}>
                                    <InputLabel required>Job Description</InputLabel>
                                    <div style={{ paddingTop: "50px" }}>
                                        <MarkdownEditor
                                            value={job.description}
                                            onChange={(event, editor) =>
                                                setJobValue("description", editor.getData())
                                            }
                                        />
                                    </div>
                                    <FormHelperText>{errors.description}</FormHelperText>
                                </FormControl>
                            </Grid>
                        </Grid>
                    ) : null}
                </Paper>
                    {!(getAccountType() === "employer") ? (
                        <Paper sx={{marginBottom: '3px'}}>
                    <Grid container justifyContent="center" alignItems="center" fullWidth maxWidth={true}>
                        <Grid item xs={12} md={6} lg={4}>
                            <Typography variant="h4" align="center" color="primary" >
                                Top 5 most similar jobs
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={2} lg={1} sx={{display: 'flex', justifyContent:"center", width:'100%'}}>
                            <Button variant="contained" color="highlight"
                                sx={{ margin: 2 }}
                                onClick={(event) => {
                                    event.preventDefault();
                                    check();
                            }}>
                                Check
                            </Button>
                        </Grid>                        
                        <Grid item xs={12}>
                            {jobs.map((job, index) => (
                                <Grid item xs={12} key={index} sx={{paddingBottom: '5px'}}>
                                    <DuplicateJobDisplay
                                        job={job}
                                        employersList={employers}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                </Paper>
                ) : ( null
                )}
            </>
            ) : (
                <Paper sx={{marginBottom: '3px'}}>
                    <Typography variant="h3" paragraph color="text" textAlign="center" mb={5}>
                        Please log in to post a new job
                    </Typography>
                </Paper>
            )}
        </PageContainer>
    );
};
export default NewJobPage;
